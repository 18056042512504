import React, { useState, useEffect } from 'react';
import { fetchToolCates } from '../../api';
import ReactSlider from 'react-slider';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const defaultImage = `${SERVER_URL}/uploads/no-image.svg`;

const CompareList = ({ tools }) => {
  const handleImageError = (e) => {
    e.target.src = defaultImage;
  }
  const [toolCates, setToolCates] = useState([]);
  const [toolCatesMap, setToolCatesMap] = useState({});
  const [headers, setHeaders] = useState([]);
  const [filteredTools, setFilteredTools] = useState([]);
  const [filters, setFilters] = useState({});  

  useEffect(() => {
    // 장비분류 목록 가져오기
    fetchToolCates().then(response => {
        setToolCates(response.data);
        // 장비분류 ID와 이름 매핑 객체 생성
        const toolCatesMap = response.data.reduce((acc, toolCate) => {
            acc[toolCate.id] = toolCate.name;
            return acc;
        }, {});
        setToolCatesMap(toolCatesMap);
    }).catch(error => {
        console.error('There was an error fetching the toolcate!', error);
    });
  }, []);

  useEffect(() => {
    const allSpecs = tools.map(tool => {
      try {
        const innerJsonString = JSON.parse(tool.specs); // First parse
        return JSON.parse(innerJsonString); // Second parse
      } catch (error) {
        console.error('Error parsing specs JSON:', error);
        return {};
      }
    });
  
    const uniqueHeaders = new Set();
    const specOptions = {};
    const specCounts = {};
    const rangeSpecs = {};
  
    allSpecs.forEach(spec => {
      Object.keys(spec).forEach(key => {
        uniqueHeaders.add(key);
  
        if (spec[key].type === 'text') {
          if (!specOptions[key]) {
            specOptions[key] = new Set();
            specCounts[key] = {};
          }
          const value = spec[key].value;
          specOptions[key].add(value);
  
          if (!specCounts[key][value]) {
            specCounts[key][value] = 0;
          }
          specCounts[key][value]++;
        }
  
        if (spec[key].type === 'number') {
          if (!rangeSpecs[key]) {
            rangeSpecs[key] = { min: 0, max: null }; // 최소값을 0으로 설정
          }
          const minValue = parseFloat(spec[key].value);
          const maxValue = parseFloat(spec[key].value);
  
          // 최소값이 0보다 작으면 0으로 설정
          if (minValue < rangeSpecs[key].min) {
            rangeSpecs[key].min = 0;
          }
  
          // 최대값 설정
          if (rangeSpecs[key].max === null || maxValue > rangeSpecs[key].max) {
            rangeSpecs[key].max = maxValue;
          }
        }
  
        if (spec[key].type === 'range') {
          if (!rangeSpecs[key]) {
            rangeSpecs[key] = { min: 0, max: null }; // 최소값을 0으로 설정
          }
          const minValue = parseFloat(spec[key].min || 0);
          const maxValue = parseFloat(spec[key].max || 0);
  
          if (minValue < rangeSpecs[key].min) {
            rangeSpecs[key].min = Math.max(0, minValue);
          }
  
          if (rangeSpecs[key].max === null || maxValue > rangeSpecs[key].max) {
            rangeSpecs[key].max = maxValue;
          }
        }
      });
    });
  
    setHeaders(Array.from(uniqueHeaders));
  
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      for (const [key, options] of Object.entries(specOptions)) {
        updatedFilters[key] = {
          type: 'text',
          options: Array.from(options),
          selected: [],
          counts: specCounts[key]
        };
      }
      for (const [key, range] of Object.entries(rangeSpecs)) {
        if (rangeSpecs[key].min === null) {
          rangeSpecs[key].min = 0; // 최종 필터 초기화시 최소값을 0으로 설정
        }
        updatedFilters[key] = {
          type: 'number', // number 타입 설정
          min: range.min,
          max: range.max,
          range: [range.min, range.max]
        };
      }
      return updatedFilters;
    });
  
    setFilteredTools(tools);
  }, [tools]);

  const applyFilters = () => {
    const newFilteredTools = tools.filter(tool => {
      try {
        const innerJsonString = JSON.parse(tool.specs); // First parse
        const specsObj = JSON.parse(innerJsonString); // Second parse
  
        for (const [key, filter] of Object.entries(filters)) {
          if (filter.type === 'number') {
            // 유저가 슬라이더를 조작했는지 확인
            if (filter.range[0] !== filter.min || filter.range[1] !== filter.max) {
              const value = specsObj[key] ? parseFloat(specsObj[key].value) : null;
  
              if (value === null || value < filter.range[0] || value > filter.range[1]) {
                return false;
              }
            }
          } else if (filter.type === 'text') {
            // 텍스트 필터 적용 로직
            const value = specsObj[key] ? specsObj[key].value : '';
            if (filter.selected.length > 0 && !filter.selected.includes(value)) return false;
          } else if (filter.type === 'range') {
            // 범위 필터 적용 로직 (필터가 설정된 경우에만 적용)
            if (filter.range[0] !== filter.min || filter.range[1] !== filter.max) {
              const rangeObj = specsObj[key] || {};
              const rangeMin = parseFloat(rangeObj.min) || 0;
              const rangeMax = parseFloat(rangeObj.max) || 0;
  
              if (
                rangeMin > (filter.range ? filter.range[1] : filter.max) || 
                rangeMax < (filter.range ? filter.range[0] : filter.min)
              ) {
                return false;
              }
            }
          }
        }
  
        return true;
      } catch (error) {
        console.error('Error applying filters:', error);
        return false;
      }
    });
  
    setFilteredTools(newFilteredTools);
  };

  const handleBrandChange = (e) => {
    const { name, value, checked } = e.target;
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[name]) {
        updatedFilters[name].selected = checked
          ? [...updatedFilters[name].selected, value]
          : updatedFilters[name].selected.filter(item => item !== value);
      }
      return updatedFilters;
    });
  };

  const handleSliderChange = (key, values) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[key]) {
        updatedFilters[key].range = values;
      }
      return updatedFilters;
    });
  };

  const resetFilters = () => {
    // Reset filters to initial state
    setFilters({});
    setFilteredTools(tools);
  };

  const renderFilterControls = () => {
    return headers.map(header => {
      const filter = filters[header];
      if (!filter) return null;
  
      if (filter.type === 'number' || filter.type === 'range') {
        return (
          <div key={header} className='filter-group'>
            <div class="label-wrap">
              <label>{header}</label>
            </div>
            <div className="slide-wrap">
              <div>{filter.range[0]} - {filter.range[1]}</div>
              <ReactSlider
                value={filter.range}
                onChange={values => handleSliderChange(header, values)}
                min={filter.min}
                max={filter.max}
                step={100}
                className={filter.range[0] !== filter.min || filter.range[1] !== filter.max ? 'slider-modified horizontal-slider' : 'slider-default horizontal-slider'}
                thumbClassName='thumb'
                trackClassName='track'
                ariaLabel={['Min value', 'Max value']}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
              />
            </div>
          </div>
        );
      }
  
      if (filter.type === 'text') {
        return (
          <div key={header} className='filter-group'>
            <label><span>{header}</span>
              <div className='check-wrap'>
              {filter.options.map(option => (
                <label key={option}>
                  <input
                    type="checkbox"
                    name={header}
                    value={option}
                    checked={filter.selected.includes(option)}
                    onChange={handleBrandChange}
                  />
                  {option} ({filter.counts[option]})
                </label>
              ))}
              </div>
            </label>
          </div>
        );
      }
  
      return null;
    });
  };

  const renderSpecs = (specs) => {
    try {
      const innerJsonString = JSON.parse(specs);
      const specsObj = JSON.parse(innerJsonString);
      return headers.map(header => {
        const spec = specsObj[header];
        if (spec) {
          if (spec.type === 'range') {
            return (
              <td key={header}>
                {spec.min} ~ {spec.max}
              </td>
            );
          } else if (spec.value !== undefined) {
            return (
              <td key={header}>
                {spec.type === 'number' ? spec.value.toLocaleString() : spec.value}
              </td>
            );
          }
        }
        return <td key={header}></td>;
      });
    } catch (error) {
      console.error('Error parsing specs JSON:', error);
      return headers.map(() => <td></td>);
    }
  };

  return (
    <div className='client-table-wrap'>
      <div className='filters'>
        <div className='title'>필터</div>
        {renderFilterControls()}
        <button onClick={applyFilters}>조회</button>
      </div>
      <div className='table-head'>
        <div className='idx'>No</div>
        <div className='game'>장비분류</div>
        <div className='tool'>장비</div>
        {headers.map(header => (
          <div className='spec-header' key={header}>{header}</div>
        ))}
      </div>
      <div className='table-body'>
        {filteredTools.map((tool, index) => (
          <div className='table-row' key={tool.id}>
            <div className='idx'>{index + 1}</div>
            <div className='game'>{toolCatesMap[tool.cate2] || 'Unknown'}</div>
            <div className='tool'>
              {tool.thumbnail ? (
                <img src={`${SERVER_URL}${tool.thumbnail}`} onError={handleImageError} />
              ) : (
                <img src={defaultImage} />
              )}
              {tool.link.length > 8 ? (
                <a href={tool.link} target="_blank">{tool.name}</a>
              ): (
                <p>{tool.name}</p>
              )}
             
            </div>
            {renderSpecs(tool.specs)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompareList;