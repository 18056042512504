// components/layout/Layout.jsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './layoutClient.css';

const LayoutFooter = () => {
    return (
        <>
            <div className='footer'>
                <div className='inner'>
                    <div className='top-wrap'>
                        <div className='logo-wrap'><img src={`${process.env.PUBLIC_URL}/icon-logo.svg`} /><Link to='/'>LOGO</Link></div>
                        <div className='sns-wrap'><a href="https://facebook.com" target="_blank"><img src={`${process.env.PUBLIC_URL}/img/icon-facebook.svg`} /></a></div>
                    </div>
                    <div className='bottom-wrap'>
                        <div className='info-wrap'>
                            <div className='info'><img src={`${process.env.PUBLIC_URL}/img/icon-mail.svg`} />contact@funnyground.kr</div>
                            <div className='info'><img src={`${process.env.PUBLIC_URL}/img/icon-phone.svg`} />000-0000-0000</div>
                        </div>
                        <div className='copy'>© 2024 Jangbibug. All rights reserved.</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LayoutFooter;
