import React, { useState, useEffect } from 'react';
import { fetchPlayers, fetchSettingsByPlayerId, deletePlayer, fetchTeams, fetchGames } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import PlayerList from '../../components/player/playerList';

const App = () => {
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedGame, setSelectedGame] = useState('');
  const [searchName, setSearchName] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadPlayers();
    loadTeams();
    loadGames();
  }, []);

  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
      setFilteredPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadTeams = () => {
    fetchTeams().then(response => {
      setTeams(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadGames = () => {
    fetchGames().then(response => {
      setGames(response.data);
    }).catch(error => {
      console.error('Error loading games:', error);
    });
  };

  /*const handleFilter = () => {
    const newFilteredPlayers = players.filter(player => {
      return (selectedTeam === '' || player.team_id == selectedTeam) &&
             (selectedGame === '' || player.game == selectedGame);
    });
    setFilteredPlayers(newFilteredPlayers);
  };*/
  const handleFilter = () => {
    const newFilteredPlayers = players.filter(player => {
      const matchTeam = selectedTeam === '' || player.team_id === selectedTeam;
      const matchGame = selectedGame === '' || player.game === selectedGame;
      const matchName = searchName === '' || player.playId.toLowerCase().includes(searchName.toLowerCase());
      return matchTeam && matchGame && matchName;
    });
    setFilteredPlayers(newFilteredPlayers);
  };

  const handleEditPlayer = (id) => {
    navigate(`/dashboard/player/modify/${id}`);
  };

  const handleDeletePlayer = (id) => {
    deletePlayer(id).then(() => {
      loadPlayers();
    }).catch((error) => {
      console.error("Error deleting player:", error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>선수 관리</div>

        <div className='filters'>
          <div className='filter'>
            <select value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)}>
              <option value=''>게임 선택</option>
              {games.map(game => (
                <option key={game.id} value={game.id}>{game.name}</option>
              ))}
            </select>
            <select value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
              <option value=''>팀 선택</option>
              {teams.map(team => (
                <option key={team.id} value={team.id}>{team.name}</option>
              ))}
            </select>
            <button onClick={handleFilter}>조회</button>
          </div>
          <div className='search'>
            <input
              type="text"
              placeholder="선수명"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
            <button onClick={handleFilter}></button>
          </div>
        </div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {filteredPlayers.length}</div>
          <div className='button-wrap'><Link to="/dashboard/player/write">선수 등록</Link></div>
        </div>

        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>No</div>
            <div className='game'>게임</div> 
            <div className='team'>팀</div> 
            <div className='player'>선수</div>
            <div className='controll'>관리</div>
          </div>
          <PlayerList players={filteredPlayers} onEdit={handleEditPlayer} onDelete={handleDeletePlayer} />
        </div>
      </div>
    </>
  );
};

export default App;