import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPostById, incrementPostLikes, decrementPostLikes, postComment, fetchCommentsByPostId } from '../../api';
import { jwtDecode } from 'jwt-decode';
import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import CommentList from './CommentList';
import CommentForm from './CommentForm';

const PostDetail = () => {
  const { id } = useParams();  // URL에서 게시글 ID를 가져옴
  const [post, setPost] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchPostAndComments = async () => {
      try {
        const postResponse = await fetchPostById(id);
        setPost(postResponse.data);
        const commentsResponse = await fetchCommentsByPostId(id);
        setComments(commentsResponse.data);
      } catch (error) {
        console.error('Error fetching post or comments:', error);
      }
    };

    fetchPostAndComments();
  }, [id]);

  const handleLikeToggle = async () => {
    try {
      // 상태를 먼저 업데이트하여 UI에 즉시 반영
      setPost(prevPost => {
        const updatedPost = { ...prevPost[0], likes: isLiked ? prevPost[0].likes - 1 : prevPost[0].likes + 1 };
        return [updatedPost];
      });
  
      // 서버에 요청을 보냄
      if (isLiked) {
        await decrementPostLikes(id);
      } else {
        await incrementPostLikes(id);
      }
  
      // 좋아요 상태를 토글
      setIsLiked(!isLiked);
    } catch (error) {
      console.error('There was an error updating likes!', error);
  
      // 에러 발생 시, 이전 상태로 되돌림
      setPost(prevPost => {
        const revertedPost = { ...prevPost[0], likes: isLiked ? prevPost[0].likes + 1 : prevPost[0].likes - 1 };
        return [revertedPost];
      });
    }
  };


  const handleAddComment = async (content, parentId = null) => {
    const token = localStorage.getItem('token');
    try {
      // JWT 디코딩
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;
      await postComment(id, userId, content, parentId);
      const commentsResponse = await fetchCommentsByPostId(id);
      setComments(commentsResponse.data);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  // 대댓글을 포함한 전체 댓글 수 계산 함수
  const calculateTotalComments = (comments) => {
    let total = 0;
    comments.forEach(comment => {
      total += 1;
      if (comment.replies && comment.replies.length > 0) {
        total += calculateTotalComments(comment.replies);
      }
    });
    return total;
  };

  if (!post) return <div>Loading...</div>;

  const totalComments = calculateTotalComments(comments);

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
            <div className="post-detail">
                <div className='post-title'>
                    <h1>{post[0].title}</h1>
                    <p className='date'>{new Date(post[0].created_at).toLocaleString()}</p>
                </div>
                <div className='post-info'>
                    <p className='writer'>{post[0].author || 'Unknown'}</p>
                    <div className='info-wrap'>
                        <p>조회 {post[0].views}</p>
                        <p>추천 {post[0].likes}</p>
                    </div>
                </div>
                <div className='post-contents'>
                    <div className='contents-inner'>
                         {/* HTML 콘텐츠를 안전하게 렌더링 */}
                         <div dangerouslySetInnerHTML={{ __html: post[0].content }} />
                    </div>
                    <div class="recommand-wrap">
                      <button onClick={handleLikeToggle} className={isLiked ? 'active' : ''}>
                        <img src={`${process.env.PUBLIC_URL}/img/icon-like.svg`} />{post[0].likes}
                      </button>
                    </div>
                </div>
                <div className='comment-wrap'>
                  <p className='comment-sum'><img src={`${process.env.PUBLIC_URL}/img/icon-pencil.svg`} />댓글 {'('}{totalComments}{')'}</p>
                  <CommentForm postId={id} onSubmit={handleAddComment} />
                  <CommentList comments={comments} onAddReply={handleAddComment} />
                </div>
            </div>
        </div>
      </div>
      <LayoutFooter />
    </>
  );
};

export default PostDetail;