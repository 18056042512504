import React, { useState, useEffect } from 'react';
import { fetchTools, deleteTool, fetchToolCates } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import ToolList from '../../components/tool/ToolList';

const App = () => {
  const [tools, setTools] = useState([]);
  const [toolCates, setToolCates] = useState([]);
  const [cate1Options, setCate1Options] = useState([]);
  const [cate2Options, setCate2Options] = useState([]);
  const [selectedCate1, setSelectedCate1] = useState('');
  const [selectedCate2, setSelectedCate2] = useState('');
  const [searchName, setSearchName] = useState('');
  const [filteredTools, setFilteredTools] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadTools();
    loadToolCates();
  }, []);

  const loadTools = () => {
    fetchTools().then((response) => {
      setTools(response.data);
      setFilteredTools(response.data);
    }).catch((error) => {
      console.error("Error loading tools:", error);
    });
  };
  
  const loadToolCates = () => {
    fetchToolCates().then(response => {
      setToolCates(response.data);
      const cate1Options = response.data.filter(cate => cate.parent_id === 'null');
      setCate1Options(cate1Options);
    }).catch(error => {
      console.error('Error loading tool categories:', error);
    });
  };

  const handleCate1Change = (e) => {
    const selectedCate1Id = e.target.value;
    setSelectedCate1(selectedCate1Id);
    setSelectedCate2('');
    if (selectedCate1Id) {
      const cate2Options = toolCates.filter(cate => cate.parent_id === selectedCate1Id);
      setCate2Options(cate2Options);
    } else {
      setCate2Options([]);
    }
  };

  const handleFilter = () => {
    const newFilteredTools = tools.filter(tool => {
      const matchCate1 = selectedCate1 === '' || tool.cate1 === selectedCate1;
      const matchCate2 = selectedCate2 === '' || tool.cate2 === selectedCate2;
      const matchName = searchName === '' || tool.name.toLowerCase().includes(searchName.toLowerCase());
      return matchCate1 && matchCate2 && matchName;
    });
    setFilteredTools(newFilteredTools);
  };

  const handleEditTool = (id) => {
    navigate(`/dashboard/tool/modify/${id}`);
  };


  const handledeleteTool = (id) => {
    deleteTool(id).then(() => {
      loadTools();
    }).catch((error) => {
      console.error("Error deleting tool:", error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>장비 관리</div>

        <div className='filters'>
          <div className='filter'>
            <select value={selectedCate1} onChange={handleCate1Change}>
              <option value=''>대분류 선택</option>
              {cate1Options.map(cate => (
                <option key={cate.id} value={cate.id}>{cate.name}</option>
              ))}
            </select>
            <select value={selectedCate2} onChange={(e) => setSelectedCate2(e.target.value)}>
              <option value=''>하위분류 선택</option>
              {cate2Options.map(cate => (
                <option key={cate.id} value={cate.id}>{cate.name}</option>
              ))}
            </select>
            <button onClick={handleFilter}>조회</button>
          </div>
          <div className='search'>
            <input
              type="text"
              placeholder="장비명 검색"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
            <button onClick={handleFilter}>조회</button>
          </div>
        </div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {filteredTools.length}</div>
          <div className='button-wrap'><Link to="/dashboard/tool/write">장비 등록</Link></div>
        </div>

        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>No</div>
            <div className='game'>대분류</div> 
            <div className='game'>하위분류</div> 
            <div className='player'>장비</div>
            <div className='controll'>관리</div>
          </div>
          <ToolList tools={filteredTools} onEdit={handleEditTool} onDelete={handledeleteTool} />
        </div>
      </div>
    </>
  );
};

export default App;