import React, { useState, useEffect } from 'react';
import { fetchPosts, deletePost, fetchBoards, pinPost } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import PostList from '../../components/post/PostList';

const App = () => {
  const [posts, setPosts] = useState([]);
  const [boards, setBoards] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBoard, setSelectedBoard] = useState('');
  const [searchName, setSearchName] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadPosts();
    loadBoards();
  }, []);

  const loadPosts = () => {
    fetchPosts().then((response) => {
      setPosts(response.data);
      setFilteredPosts(response.data); 
    }).catch((error) => {
      console.error("Error loading posts:", error);
    });
  };
  
  const loadBoards = () => {
    fetchBoards().then(response => {
      setBoards(response.data);
      const uniqueCategories = [...new Set(response.data.map(board => board.parent_category))];
      setCategories(uniqueCategories);
    }).catch(error => {
      console.error('Error loading tool categories:', error);
    });
  };

  const handleFilter = () => {
    const newFilteredPosts = posts.filter(post => {
      const matchBoard = selectedBoard === '' || post.category_id == selectedBoard;
      const matchCategory = selectedCategory === '' || boards.find(board => board.id === post.category_id).parent_category === selectedCategory;
      const matchName = searchName === '' || post.title.toLowerCase().includes(searchName.toLowerCase()) || post.content.toLowerCase().includes(searchName.toLowerCase());
      return matchBoard && matchCategory && matchName;
    });
    setFilteredPosts(newFilteredPosts);
  };

  const handleDeletePost = (id) => {
    deletePost(id).then(() => {
      loadPosts();
    }).catch((error) => {
      console.error("Error deleting post:", error);
    });
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedBoard('');
  };

  const handlePinPost = async (postId, isPinned) => {
    try {
      await pinPost(postId, isPinned); // 현재 고정 상태의 반대값을 서버로 전송
      loadPosts(); // 게시물 목록을 새로고침
    } catch (error) {
      console.error('Error pinning post:', error);
      alert('게시물 고정/해제 중 오류가 발생했습니다.');
    }
  };


  const filteredBoards = boards.filter(board => board.parent_category === selectedCategory || selectedCategory === '');

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>게시판 관리</div>
        <div className='subCate'>
          <Link to="/dashboard/board/PostList" className='selected'>게시물 관리</Link>
          <Link to="/dashboard/board/list">게시판 설정</Link>
        </div>

        <div className='filters'>
          <div className='filter'>
            <select value={selectedCategory} onChange={handleCategoryChange}>
              <option value=''>카테고리 선택</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            <select value={selectedBoard} onChange={(e) => setSelectedBoard(e.target.value)}>
              <option value=''>게시판 선택</option>
              {filteredBoards.map(board => (
                <option key={board.id} value={board.id}>{board.name}</option>
              ))}
            </select>
            <button onClick={handleFilter}>조회</button>
          </div>
          <div className='search'>
            <input
              type="text"
              placeholder="게시글 제목"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
            <button onClick={handleFilter}></button>
          </div>
        </div>


        <div className='util-wrap'>
          <div className='totalCnt'>Total {posts.length}</div>
        </div>

        <div className='table-wrap'>
          <div className='table-head'>
            <div className='idx'>No</div>
            <div className='game'>게시판</div> 
            <div className='game'>작성자</div> 
            <div className='player'>게시글 제목</div>
            <div className='controll'>관리</div>
          </div>
          <PostList posts={filteredPosts} onDelete={handleDeletePost} onPinPost={handlePinPost} />
        </div>
      </div>
    </>
  );
};

export default App;