import React, { useState, useEffect } from 'react';
import { fetchBoards, fetchPostsCategory, fetchBestPostsCategory } from '../../api';
import { useParams } from 'react-router-dom';

import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import CategoryList from './CategoryList.js';
import PostList from './PostList';
import BestList from './BestList';

const CommunityList = () => {
  const { category } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [posts, setPosts] = useState([]);
  const [boards, setBoards] = useState([]);
  const [bestPostsByCategory, setBestPostsByCategory] = useState({});

  useEffect(() => {
    const loadBoards = async () => {
      try {
        const response = await fetchBoards();
        setBoards(response.data);
      } catch (error) {
        console.error('There was an error fetching the boards!', error);
      }
    };

    loadBoards();
  }, []);

  // 카테고리별 Best 게시물 가져오기
  useEffect(() => {
    const fetchBestPosts = async () => {
      if (!category || boards.length === 0) return;

      // parent_category가 현재 URL의 category와 일치하는 하위 게시판만 필터링
      const filteredBoards = boards.filter(board => board.parent_category === category);
      const boardIds = filteredBoards.map(board => board.id);

      const bestPostsPromises = boardIds.map(async (id) => {
        const bestPosts = await fetchBestPostsCategory(id);
        return { boardId: id, posts: bestPosts.data };
      });

      try {
        const results = await Promise.all(bestPostsPromises); // 각 하위 카테고리의 Best Posts
        const bestPostsByCategoryMap = results.reduce((acc, { boardId, posts }) => {
          acc[boardId] = posts; // 하위 카테고리 ID별로 Best Posts 저장
          return acc;
        }, {});
        setBestPostsByCategory(bestPostsByCategoryMap);
      } catch (error) {
        console.error('There was an error fetching the best posts!', error);
      }
    };

    fetchBestPosts();
  }, [category, boards]);


  useEffect(() => {
    const fetchAllPosts = async () => {
      if (!category || boards.length === 0) return;

      setSelectedCategory(null); // 대분류로 이동 시, 선택된 하위 카테고리를 초기화

      const boardIds = boards.filter(board => board.parent_category === category).map(board => board.id);
      const postsPromises = boardIds.map(id => fetchPostsCategory(id));

      try {
        const results = await Promise.all(postsPromises);
        const allPosts = results.flatMap(result => result.data);
        setPosts(allPosts);
      } catch (error) {
        console.error('There was an error fetching the posts!', error);
      }
    };

    fetchAllPosts();
  }, [category, boards]);

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
            <div className='path'>Home / {category}</div>
            {/* 카테고리별로 Best 게시물 표시 */}
            <div className='best-post-wrap'>
              {boards.filter(board => board.parent_category === category).map((board) => (
                <div key={board.id} className="category-section">
                  <h3>{board.name} BEST</h3>
                  {bestPostsByCategory[board.id]?.length > 0 ? (
                    <BestList posts={bestPostsByCategory[board.id]} boards={boards} isBest={true} />
                  ) : (
                    <p>아직 게시글이 없습니다.</p>
                  )}
                </div>
              ))}
            </div>
            <div className='board-wrap'>
              <CategoryList 
                onSelectCategory={setSelectedCategory} 
                parentCategory={category}
                boards={boards} 
              />
              {/* 대분류 카테고리만 선택된 경우, 모든 게시글 표시 */}
              {selectedCategory === null && <PostList parentCategory={category} posts={posts} boards={boards} />}
              {/* 하위 카테고리 선택 시, 해당 카테고리의 게시글만 표시 */}
              {selectedCategory && <PostList parentCategory={category} categoryId={selectedCategory} posts={posts} boards={boards} />}
            </div>
        </div>
      </div>
      <LayoutFooter />
    </>
  );
};

export default CommunityList;