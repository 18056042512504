import React, { useState, useEffect } from 'react';
import { createToolCate, updateToolCate, fetchToolCateById } from '../../api';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ToolCateForm = ({ toolCateId, onSave, onDelete, parentId }) => {
  const [previewIcon, setPreviewIcon] = useState(null); 
  const [formData, setFormData] = useState({
    name: '',
    exposure: '노출함',
    icon: null,
    parent_id: parentId || null,
    specs: []
  });


  useEffect(() => {
    if (toolCateId && !parentId) {
      fetchToolCateById(toolCateId).then((response) => {
        const toolCate = response.data.find((s) => s.id === toolCateId);
        if (toolCate.specs) {
          toolCate.specs = JSON.parse(toolCate.specs);
        }
        setFormData(toolCate);
        if (toolCate.icon) {
          setPreviewIcon(`${SERVER_URL}${toolCate.icon}`);
        } else {
          setPreviewIcon(`${SERVER_URL}/uploads/no-image.svg`);
        }
      });
    } else {
      setFormData({
        name: '',
        exposure: '노출함',
        icon: null,
        parent_id: parentId || null,
        specs: []
      });
      setPreviewIcon(`${SERVER_URL}/uploads/no-image.svg`);
    }
  }, [toolCateId, parentId]);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, icon: file });
        setPreviewIcon(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSpecChange = (index, e) => {
    const { name, value } = e.target;
    const newSpecs = [...formData.specs];
    newSpecs[index][name] = value;
    setFormData({ ...formData, specs: newSpecs });
  };

  const handleSpecDelete = (index) => {
    const newSpecs = [...formData.specs];
    newSpecs.splice(index, 1);
    setFormData({ ...formData, specs: newSpecs });
  };

  const handleSpecAdd = () => {
    setFormData({
      ...formData,
      specs: [...formData.specs, { name: '', type: 'text' }]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('exposure', formData.exposure);
    formDataToSend.append('icon', formData.icon || '');
    formDataToSend.append('parent_id', formData.parent_id);
    formDataToSend.append('specs', JSON.stringify(formData.specs));
    
    console.log('test',formDataToSend)

    if (toolCateId) {
      updateToolCate(toolCateId, formDataToSend).then(onSave);
    } else {
      createToolCate(formDataToSend).then(onSave);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="title-wrap">
            {toolCateId ? (
                <>
                  <p className="title">
                    <img src="/img/icon-folder-active.png" alt="icon" />
                    {formData.name}
                  </p>
                  <div className='button-wrap'>
                    <button type="submit">수정 완료</button>
                    <button onClick={() => onDelete(toolCateId)} className='delete'>삭제</button>
                  </div>
                </>
            ) : (
                <>
                  <p className="title">
                    <img src="/img/icon-folder-active.png" alt="icon" />
                    새 장비분류
                  </p>
                  <div className='button-wrap'>
                    <button type="submit">저장</button>
                  </div>
                </>
            )}
        </div>
        <div className="sub-title">기본 설정</div>
        {parentId ? (
            <div className="input-wrap">
              <p className="label">대분류</p>
              <input type="text" name="parent_id" value={formData.parent_id} onChange={handleChange} placeholder="대분류" readOnly />
            </div>
        ) : null}
        <div className="input-wrap">
          <p className="label">분류명</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="장비 분류명" />
        </div>
        <div className="input-wrap">
          <p className="label">노출여부</p>
          <label>
            <input type="radio" name="exposure" value="노출함" checked={formData.exposure === '노출함'} onChange={handleChange} />
            노출함
          </label>
          <label>
            <input type="radio" name="exposure" value="노출안함" checked={formData.exposure === '노출안함'} onChange={handleChange} />
            노출안함
          </label>
        </div>
        <div className="input-wrap">
          <p className="label">아이콘</p>
          <img src={previewIcon || `${SERVER_URL}/uploads/no-image.svg`} className="iconPreview" alt="icon preview" />
          <label className="file-button">
            <input type="file" name="icon" onChange={handleIconChange} className='displaynone' accept="image/*" />
            {previewIcon ? '변경' : '추가'}
          </label>
        </div>
        <div className="sub-title">기본 정보(스펙) 항목 설정</div>
        <div className="input-wrap spec-wrap">
          <p className="label">정보 항목</p>
          <div className='field-wrap'>
            {formData.specs.map((spec, index) => (
              <div key={index} className='field spec'>
                <input
                  type="text"
                  name="name"
                  value={spec.name}
                  onChange={(e) => handleSpecChange(index, e)}
                  placeholder="항목명"
                />
                <select
                  name="type"
                  value={spec.type}
                  onChange={(e) => handleSpecChange(index, e)}
                >
                  <option value="text">문자열</option>
                  <option value="number">숫자</option>
                  <option value="date">날짜</option>
                  <option value="range">범위</option>
                </select>
                <button
                  type="button"
                  onClick={() => handleSpecDelete(index)}
                  className='delete'
                >
                  삭제
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleSpecAdd}
              className='btnAdd'
            >
              항목 추가
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ToolCateForm;