import React, { useState, useEffect } from 'react';
import { fetchUsers, deleteUser, changeUserGrade, fetchMemberships  } from '../../api';
import { Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import UserList from '../../components/member/MemberList';

const MemberList = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [newGradeId, setNewGradeId] = useState('');
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    loadUsers();
    fetchMemberships()
      .then(response => {
        setMemberships(response.data);
      })
      .catch(error => {
        console.error('Error fetching memberships:', error);
      });
  }, []);

  const loadUsers = () => {
    fetchUsers().then((response) => {
      setUsers(response.data);
      console.log(response.data);
    }).catch((error) => {
      console.error("Error loading users:", error);
    });
  };

  const handleDeleteUser = (id) => {
    deleteUser(id).then(() => {
      loadUsers();
    }).catch((error) => {
      console.error("Error deleting users:", error);
    });
  };

  const handleCheckboxChange = (id) => {
    setSelectedUsers(prev =>
      prev.includes(id) ? prev.filter(uid => uid !== id) : [...prev, id]
    );
  };

  const handleChangeGrade = () => {
    if (selectedUsers.length === 0 || !newGradeId) {
      alert('Please select users and a grade.');
      return;
    }

    changeUserGrade(selectedUsers, newGradeId).then(() => {
      loadUsers();
      setSelectedUsers([]);
      setNewGradeId('');
    }).catch((error) => {
      console.error("Error changing user grade:", error);
    });
  };

  return (
    <>
      <Layout />
      <div className='contents'>
        <div className='titleArea'>회원 관리</div>
        <div className='subCate'>
          <Link to="/dashboard/member/list" className='selected'>회원 리스트</Link>
          <Link to="/dashboard/member/grade" >회원 등급 관리</Link>
        </div>

        <div className='util-wrap'>
          <div className='totalCnt'>Total {users.length}</div>
          <dlv className='change-grade'>
            <select value={newGradeId} onChange={(e) => setNewGradeId(e.target.value)}>
              <option value="">등급 선택</option>
              {memberships.map(grade => (
                <option key={grade.id} value={grade.id}>
                  {grade.name}
                </option>
              ))}
            </select>
            <button onClick={handleChangeGrade}>으로 선택한 회원 등급 변경</button>
          </dlv>
        </div>
        <div className='table-wrap'>
          <div className='table-head'>
            <div className='check'><input type='checkbox' /></div>
            <div className='idx'>No</div>
            <div className='category'>회원등급</div> 
            <div className='category'>가입일자</div> 
            <div className='category'>닉네임</div>
            <div className='email'>이메일</div>
            <div className='category'>패널티</div>
            <div className='controll'>관리</div>
          </div>
          <UserList 
            members={users} 
            onDelete={handleDeleteUser} 
            onCheckboxChange={handleCheckboxChange} 
            selectedUsers={selectedUsers}
          />
        </div>
      </div>
    </>
  );
};

export default MemberList;