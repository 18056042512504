import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { fetchGameById, fetchPlayers, fetchTeams, fetchGames } from '../../api';

import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import PlayerList from './PlayerList';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GameDetail = () => {
  const { id } = useParams();
  const [game, setGame] = useState([]);

  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedGame, setSelectedGame] = useState('');
  const [searchName, setSearchName] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadGameDetails(); // 게임 상세 로드
    loadPlayers(); // 선수 목록 로드
    loadTeams(); // 팀 목록 로드
    loadGames(); // 게임 목록 로드
  }, [id]);

  const loadGameDetails = () => {
    fetchGameById(id).then((response) => {
      setGame(response.data[0]);
      setSelectedGame(id); // URL 파라미터와 일치하는 게임 선택
    }).catch((error) => {
      console.error("Error loading game details:", error);
    });
  };


  const loadPlayers = () => {
    fetchPlayers().then((response) => {
      setPlayers(response.data);
      setFilteredPlayers(response.data);
    }).catch((error) => {
      console.error("Error loading players:", error);
    });
  };
  
  const loadTeams = () => {
    fetchTeams().then(response => {
      setTeams(response.data);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  const loadGames = () => {
    fetchGames().then(response => {
      setGames(response.data);
    }).catch(error => {
      console.error('Error loading games:', error);
    });
  };

  const handleFilter = () => {
    if (!players.length) return; // 선수 목록이 로드되지 않았으면 필터링하지 않음
    const newFilteredPlayers = players.filter(player => {
      const matchTeam = selectedTeam === '' || player.team_id === selectedTeam;
      const matchGame = selectedGame === '' || player.game === selectedGame;
      const matchName = searchName === '' || player.playId.toLowerCase().includes(searchName.toLowerCase()) || player.name.toLowerCase().includes(searchName.toLowerCase());
      return matchTeam && matchGame && matchName;
    });
    setFilteredPlayers(newFilteredPlayers);
  };

  // 필터가 변경될 때마다 handleFilter 함수 호출
  useEffect(() => {
    handleFilter(); // 필터 적용
  }, [players, selectedTeam, selectedGame, searchName]);

  // 게임 선택 시 URL을 업데이트하는 함수
  const handleGameChange = (e) => {
    const selectedGameId = e.target.value;
    setSelectedGame(selectedGameId);
    if (selectedGameId) {
      navigate(`/game/detail/${selectedGameId}`);
    }
  };

  return (
    <>
      <LayoutClient />
        <div className='client-contents'>
          <div className='inner'>
            <div className='path'>Home / 게임 / {game.name}</div>
            <div className='game-detail'>
                {game && (
                    <div className='info-wrap'>
                        <div className='thumb-wrap'><img src={`${SERVER_URL}${game.banner}`} alt={`${game.name} banner`} /></div>
                        <div className='info'>
                            <p className='title'>{game.name}</p>
                            <p className='description'>{game.description}</p>
                        </div>
                    </div>
                )}
                <div className='filters'>
                  <div className='filter'>
                      <select value={selectedGame} onChange={handleGameChange}>
                      <option value=''>게임 선택</option>
                      {games.map(game => (
                          <option key={game.id} value={game.id}>{game.name}</option>
                      ))}
                      </select>
                      <select value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
                      <option value=''>팀 선택</option>
                      {teams.map(team => (
                          <option key={team.id} value={team.id}>{team.name}</option>
                      ))}
                      </select>
                  </div>
                  <div className='search'>
                      <input
                      type="text"
                      placeholder="선수명, 이름 검색"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                      />
                      <button onClick={handleFilter}></button>
                  </div>
                </div>
                
                <div className='util-wrap'>
                  <div className='totalCnt'>선수목록 {filteredPlayers.length}명</div>
                </div>

                <div className='list-wrap'>
                  <PlayerList players={filteredPlayers} />
                </div>
            </div>
          </div>
        </div>
      <LayoutFooter />
    </>
  );
};

export default GameDetail;