import React, { useState } from 'react';

const ToolCateList = ({ toolCates, onEdit, onDelete, onAppendChild }) => {
  const [selectedToolCateId, setSelectedToolCateId] = useState(null);

  const handleEditClick = (toolCateId) => {
    setSelectedToolCateId(toolCateId);
  };

  // 상위 분류 및 하위 분류를 재귀적으로 렌더링하는 함수
  const renderToolCates = (parentId = 'null') => {
    const filteredToolCates = toolCates.filter(toolCate => {
      return parentId === null ? toolCate.parent_id === null : toolCate.parent_id == parentId;
    });
    console.log(`Rendering tool cates for parent ID: ${parentId}`, filteredToolCates);

    return filteredToolCates.map(toolCate => (
      <div key={toolCate.id}>
        <li>
          <div className='button-wrap'>
            <button
              onClick={() => {
                onEdit(toolCate.id);
                handleEditClick(toolCate.id);
              }}
              className={selectedToolCateId === toolCate.id ? 'selected' : ''}
            >
              {toolCate.name}
            </button>
            <button onClick={() => onAppendChild(toolCate.id)} className={selectedToolCateId === toolCate.id ? 'active' : 'disactive'}>+</button>
          </div>
        </li>
        {renderToolCates(toolCate.id).length > 0 && (
          <ul className='child-list'>
            {renderToolCates(toolCate.id)}
          </ul>
        )}
      </div>
    ));
  };
  
  return (
    <ul>
      {renderToolCates()}
    </ul>
  );
};

export default ToolCateList;