import React, { useState } from 'react';

const MemberList = ({ members, onDelete, onCheckboxChange, selectedUsers }) => {

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 20;

  const indexOfLastTool = currentPage * postsPerPage;
  const indexOfFirstTool = indexOfLastTool - postsPerPage;
  const currentPosts = members.slice(indexOfFirstTool, indexOfLastTool);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(members.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <>
      <div className='table-body'>
        <ul>
          {currentPosts.map((member, index) => (
            <li key={member.id}>
              <div className='check'>
                <input 
                  type='checkbox' 
                  checked={selectedUsers.includes(member.id)}
                  onChange={() => onCheckboxChange(member.id)} 
                />
              </div>
              <div className='idx'>{index + 1}</div>
              <div className='category'>{member.grade_name}</div> 
              <div className='category'>{formatDate(member.join_date)}</div> 
              <div className='category'>{member.username}</div>
              <div className='email'>{member.email}</div>
              <div className='category'>{member.penalty}</div>
              <div className='controll'>
                <button onClick={() => onDelete(member.id)} className='delete'>삭제</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    </>

  );
};

export default MemberList;