import React, { useState, useEffect } from 'react';
import { fetchTools, fetchToolCates, fetchToolsByCategoryID } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import ToolList from './CompareList';

const App = () => {
  const [tools, setTools] = useState([]);
  const [toolCates, setToolCates] = useState([]);
  const [selectedCate2, setSelectedCate2] = useState(null); 
  const navigate = useNavigate();

  useEffect(() => {
    loadToolCates();
  }, []);

  const loadToolCates = () => {
    fetchToolCates().then(response => {
      const categories = response.data;
      setToolCates(categories);

      // 첫 번째 카테고리를 자동으로 선택
      const firstCate = categories.find(cate => cate.parent_id !== null && cate.parent_id !== "null");
      if (firstCate) {
        setSelectedCate2(firstCate.id);
        loadToolsByCategory(firstCate.id);
      }
    }).catch(error => {
      console.error('Error loading tool categories:', error);
    });
  };

  const loadToolsByCategory = (cate2Id) => {
    fetchToolsByCategoryID(cate2Id).then(response => {
      setTools(response.data);
    }).catch(error => {
      console.error("Error loading tools by category:", error);
    });
  };

  const handleCategoryClick = (cate2Id) => {
    setSelectedCate2(cate2Id);
    loadToolsByCategory(cate2Id);
  };
  

  return (
    <>
      <LayoutClient />
        <div className='client-contents'>
          <div className='inner'>
            <div className='path'>Home / 장비비교</div>
            <div className='title-wrap'>
                <p className='title'>장비비교</p>
            </div>
            <div className="tool-category-list">
              {toolCates
                .filter(cate => cate.parent_id !== null && cate.parent_id !== "null") // 대분류 제외
                .map(cate => (
                  <button 
                    key={cate.id} 
                    onClick={() => handleCategoryClick(cate.id)}
                    className={selectedCate2 === cate.id ? 'active' : ''}
                  >
                    {cate.name}
                  </button>
              ))}
            </div>
            {selectedCate2 && <ToolList tools={tools} />}
          </div>
        </div>
      <LayoutFooter />
    </>
  );
};

export default App;