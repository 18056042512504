import React, { createContext, useState, useEffect } from 'react';
import { fetchProfile } from '../api'; // 사용자 정보를 가져오는 API 호출 함수

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // 사용자 정보를 저장할 상태

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      // 토큰이 존재하면 사용자 정보를 가져옴
      fetchProfile()
        .then(response => {
          setUser(response.data);
          console.log(response.data);
        })
        .catch(error => {
          console.error('Error fetching user profile:', error);
          // 에러 발생 시 로그아웃 처리
          logout();
        });
    }
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    // 로그인 후 즉시 사용자 정보를 가져옴
    fetchProfile()
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        console.error('Error fetching user profile:', error);
        logout();
      });
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null); // 로그아웃 시 사용자 정보 초기화
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};