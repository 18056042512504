import React, { useEffect, useState } from 'react';
import { fetchPostsCategory, fetchPosts, fetchBoards } from '../../api';
import { useNavigate, Link } from 'react-router-dom';

const PostList = ({ categoryId, posts, boards, parentCategory }) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  const getCategoryName = (id) => {
    const category = boards.find(board => board.id === id);
    return category ? category.name : 'Unknown';
  };

  // 대분류에 대한 하위 카테고리만 필터링
  const filteredPosts = categoryId
    ? posts.filter(post => post.category_id === categoryId)
    : parentCategory
      ? posts.filter(post => {
          // 대분류에 속하는 하위 카테고리만 필터링
          const boardIds = boards.filter(board => board.parent_category === parentCategory).map(board => board.id);
          return boardIds.includes(post.category_id);
        })
      : posts;

  const sortedPosts = filteredPosts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedPosts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const formatDate = (dateStr) => {
    const postDate = new Date(dateStr);
    const now = new Date();
    const today = now.toDateString();
    const postDateString = postDate.toDateString();

    if (today === postDateString) {
      return postDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    } else {
      return postDate.toLocaleDateString();
    }
  };

  return (
    <>
      <button
      onClick={() => navigate(`/community/write/${categoryId || 'null'}/${parentCategory || ''}`)}
      className='btnWrite'
    >
      글쓰기
    </button>

      <div className='board-table-head'>
        <li className='category'>카테고리</li>
        <li className='subject'>제목</li>
        <li className='writer'>글쓴이</li>
        <li className='date'>날짜</li>
        <li className='hit'>조회</li>
        <li className='recommand'>추천</li>
      </div>

      <div className='board-inner'>
        <ul>
          {currentPosts.map(post => (
            <li key={post.id}>
              <p className='category'>{getCategoryName(post.category_id)}</p>
              <p className='subject'>
                <Link to={`/community/post/${post.id}`}>{post.title}</Link>
              </p>
              <div>
                <p className='writer'>{post.author || 'Unknown'}</p>
                <p className='date'>{formatDate(post.created_at)}</p>
                <p className='hit'><span>조회</span>{post.views}</p>
                <p className='recommand'><span>추천</span>{post.likes}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 페이지네이션 버튼 */}
      <div className='pagination'>
        {pageNumbers.map(number => (
          <button key={number} onClick={() => paginate(number)} className={number === currentPage ? 'active' : ''}>
            {number}
          </button>
        ))}
      </div>
    </>
  );
};

export default PostList;