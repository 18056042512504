import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { fetchGameById, fetchPlayers, fetchPlayerById, fetchTeams, fetchGames, fetchToolById, fetchToolCateById, fetchSettings, fetchToolCates, fetchCommentsByPlayerId, playerCreateComment, fetchToolSettings, playerDeleteComment, playerUpdateComment } from '../../api';
import { jwtDecode } from 'jwt-decode';
import LayoutClient from '../../components/layout/LayoutClient';
import LayoutFooter from '../../components/layout/LayoutFooter';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import defaultImg from '../../assets/no-image.svg'
import Flags from 'react-world-flags';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const PlayerDetail = () => {
    const { id } = useParams();
    const [player, setPlayer] = useState([]);
    const [tools, setTools] = useState([]);
    const [teamMap, setTeamMap] = useState({});
    const [gameMap, setGameMap] = useState({});
    const [playerMap, setPlayerMap] = useState({});
    const [toolCategories, setToolCategories] = useState({});
    const [groupedTools, setGroupedTools] = useState({});
    const [comments, setComments] = useState([]);
    const [toolSettingsMap, setToolSettingsMap] = useState({});
    const [ingameSettingMap, setIngameSettingMap] = useState({});

    useEffect(() => {
        const fetchPlayerAndComments = async () => {
          try {
            const postResponse = await fetchPlayerById(id);
            setPlayer(postResponse.data);
            const commentsResponse = await fetchCommentsByPlayerId(id);
            setComments(commentsResponse.data);
          } catch (error) {
            console.error('Error fetching post or comments:', error);
          }
        };
    
        fetchPlayerAndComments();
    }, [id]);

    useEffect(() => {
        // fetchToolSettings를 호출하고 결과를 toolSettingsMap 상태에 저장
        fetchToolSettings().then(response => {
            const toolSettings = response.data.reduce((acc, setting) => {
                acc[setting.id] = setting; // id를 키로 하여 toolSetting을 매핑
                return acc;
            }, {});
            setToolSettingsMap(toolSettings);
        }).catch(error => {
            console.error('Error fetching tool settings:', error);
        });
    }, []);

    useEffect(() => {
        // fetchToolSettings를 호출하고 결과를 toolSettingsMap 상태에 저장
        fetchSettings().then(response => {
            const gameSettings = response.data.reduce((acc, setting) => {
                acc[setting.category] = setting; // id를 키로 하여 toolSetting을 매핑
                return acc;
            }, {});
            setIngameSettingMap(gameSettings);
        }).catch(error => {
            console.error('Error fetching tool settings:', error);
        });
    }, []);


    useEffect(() => {
        // 팀 목록 가져오기
        fetchTeams().then(response => {
            // 팀 ID와 이름 매핑 객체 생성
            const teamMap = response.data.reduce((acc, team) => {
              acc[team.id] = { name: team.name, icon: team.icon };
              return acc;
            }, {});
            setTeamMap(teamMap);
        }).catch(error => {
            console.error('There was an error fetching the teams!', error);
        });
    }, []);
    
      useEffect(() => {
          // 게임 목록 가져오기
          fetchGames().then(response => {
              // 게임 ID와 이름 매핑 객체 생성
              const gameMap = response.data.reduce((acc, game) => {
                  acc[game.id] =  { name: game.name, icon: game.icon };
                  return acc;
              }, {});
              setGameMap(gameMap);
          }).catch(error => {
              console.error('There was an error fetching the Games!', error);
          });
    }, []);

    useEffect(() => {
        fetchPlayers().then(response => {
          const playerMap = response.data.reduce((acc, player) => {
            if (!acc[player.team_id]) {
              acc[player.team_id] = [];
            }
            acc[player.team_id].push({ playId: player.playId, thumbnail: player.thumbnail, id: player.id, game: player.game });
            return acc;
          }, {});
          setPlayerMap(playerMap);
        }).catch(error => {
          console.error('There was an error fetching the players!', error);
        });
    }, []);

    // 장비 카테고리 불러오기
    useEffect(() => {
        fetchToolCates().then(response => {
            const categories = response.data.reduce((acc, cate) => {
                acc[cate.id] = { 
                    name: cate.name, 
                    parentId: cate.parent_id === "null" ? null : cate.parent_id // "null"을 null로 변환
                };
                return acc;
            }, {});
            setToolCategories(categories);
        }).catch(error => {
            console.error('Error fetching tool categories:', error);
        });
    }, []);

    useEffect(() => {
        if (player.rel_products && Object.keys(toolCategories).length > 0) {
            const toolIds = JSON.parse(player.rel_products);
            Promise.all(toolIds.map(id => fetchToolById(id))).then(responses => {
                const toolsData = responses.map(response => response.data);
                // 대분류 기준으로 그룹화
                const grouped = toolsData.reduce((acc, tool) => {
                    // cate1을 기반으로 대분류 찾기
                    const cate1 = toolCategories[tool[0].cate1]; // tool.cate1을 사용하여 대분류 확인
                    if (cate1) {  // cate1이 존재하는지 확인
                        const mainCategory = cate1.parentId === null ? cate1 : toolCategories[cate1.parentId];
    
                        if (mainCategory && mainCategory.parentId === null) {  // 대분류인지 확인
                            if (!acc[mainCategory.name]) {
                                acc[mainCategory.name] = [];
                            }
                            acc[mainCategory.name].push(tool);
                        }
                    }
    
                    return acc;
                }, {});
    
                setGroupedTools(grouped);
            }).catch(error => {
                console.error('Error fetching tools:', error);
            });
        }
    }, [player.rel_products, toolCategories]);

    const renderIngameSettings = () => {
        if (!player || !player.settings) return null;
        const settings = JSON.parse(player.settings); // JSON 파싱
        
        return settings.map((setting, index) => {
            const matchingGameSetting = ingameSettingMap[setting.category];
            const iconSrc = matchingGameSetting && matchingGameSetting.icon !== 'null' 
                ? `${SERVER_URL}${matchingGameSetting.icon}` 
                : defaultImg;

            return (
                <div key={index} className='setting-item'>
                    <div className='title'>
                        <img src={iconSrc} />
                        <h4 className='setting-category'>{setting.category}</h4>
                    </div>
                    <div className='dynamic-fields'>
                        {setting.dynamic_fields.map((field, i) => (
                            <div key={i} className='dynamic-field'>
                                <p className='field-name'>{field.name}</p>
                                <p className='field-value'>{field.value}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        );
    };

    const renderToolSettings = () => {
        if (!player || !player.toolsettings) return null;
        let toolSettings;
        try {
            toolSettings = JSON.parse(player.toolsettings); // JSON 파싱
        } catch (e) {
            console.error('Error parsing toolSettings JSON:', e);
            toolSettings = [];
        }
        return toolSettings.map((toolSetting, index) => {
            const matchingToolSetting = toolSettingsMap[toolSetting.toolSettingId]; // toolSettingsMap에서 매칭되는 아이템 찾기
            const iconSrc = matchingToolSetting && matchingToolSetting.icon !== 'null' 
                ? `${SERVER_URL}${matchingToolSetting.icon}` 
                : defaultImg;

            return (
                <div key={index} className='setting-item'>
                    <div className='title'>
                        <img src={iconSrc} alt={toolSetting.toolSettingName} />
                        <h4 className='setting-category'>{toolSetting.toolSettingName}</h4>
                    </div>
                    <div className='dynamic-fields'>
                        {toolSetting.settings.map((setting, i) => (
                            <div key={i} className='dynamic-field'>
                                <p className='field-name'>{setting.name}</p>
                                <p className='field-value'>{setting.value}</p>
                            </div>
                        ))}
                    </div>
                </div>
            );
        });
    };

    const handleAddComment = async (content, parentId = null) => {
        const token = localStorage.getItem('token');
        try {
          // JWT 디코딩
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.id;
          await playerCreateComment(id, userId, content, parentId);
          const commentsResponse = await fetchCommentsByPlayerId(id);
          setComments(commentsResponse.data);
        } catch (error) {
          console.error('Error adding comment:', error);
        }
      };

    // 대댓글을 포함한 전체 댓글 수 계산 함수
    const calculateTotalComments = (comments) => {
        let total = 0;
        comments.forEach(comment => {
        total += 1;
        if (comment.replies && comment.replies.length > 0) {
            total += calculateTotalComments(comment.replies);
        }
        });
        return total;
    };

    const totalComments = calculateTotalComments(comments);


    return (
        <>
        <LayoutClient />
            <div className='client-contents'>
                <div className='inner'>
                    <div className='path'>
                        Home / 게임 / 
                        {gameMap[player.game] && (
                            <Link to={`/game/detail/${player.game}`}> {gameMap[player.game].name}</Link>
                        )} / 
                        {teamMap[player.team_id] && (
                            <Link to={`/game/detail/${player.game}`}> {teamMap[player.team_id].name}</Link>
                        )}
                    </div>

                    <div className='player-wrap'>
                        {player && (
                            <>
                                <div className='info-wrap'>
                                    <div className='thumb-wrap'><img src={`${SERVER_URL}${player.thumbnail}`} alt={`${player.playId} banner`} /></div>
                                    <div className='info'>
                                        <p className='playId'>{player.playId}</p>
                                        <div className='sns-wrap'>
                                            {player.snsAfreeca && player.snsAfreeca !== 'https://' ? (
                                                <a href={player.snsAfreeca} target='_blank'><img alt="" src={`${SERVER_URL}/uploads/i-afreeca.svg`} /></a>
                                            ): ([])}
                                            {player.snsX && player.snsX !== 'https://' ? (
                                                <a href={player.snsX} target='_blank'><img alt="" src={`${SERVER_URL}/uploads/i-x.svg`} /></a>
                                            ): ([])}
                                            {player.snsInstagram && player.snsInstagram !== 'https://' ? (
                                                <a href={player.snsInstagram} target='_blank'><img alt="" src={`${SERVER_URL}/uploads/i-instagram.svg`} /></a>
                                            ): ([])}
                                            {player.snsYoutube && player.snsYoutube !== 'https://' ? (
                                                <a href={player.snsYoutube} target='_blank'><img alt="" src={`${SERVER_URL}/uploads/i-youtube.svg`} /></a>
                                            ): ([])}
                                            {player.snsTictok && player.snsTictok !== 'https://' ? (
                                                <a href={player.snsTictok} target='_blank'><img alt="" src={`${SERVER_URL}/uploads/i-tictok.svg`} /></a>
                                            ): ([])}
                                            {player.snsChzzk && player.snsChzzk !== 'https://' ? (
                                                <a href={player.snsChzzk} target='_blank'><img alt="" src={`${SERVER_URL}/uploads/i-chzzk.svg`} /></a>
                                            ): ([])}
                                            {player.snsFacebook  && player.snsFacebook !== 'https://' ? (
                                                <a href={player.snsFacebook} target='_blank'><img alt="" src={`${SERVER_URL}/uploads/i-facebook.svg`} /></a>
                                            ): ([])}
                                        </div>
                                        <div className='field'>
                                            <p className='label'>이름</p>
                                            <div className='data'>{player.name}</div>
                                        </div>
                                        <div className='field'>
                                            <p className='label'>국가</p>
                                            <div className='data'>
                                                {player.nationality && (
                                                    <Flags code={player.nationality} style={{ width: 18, height: 12 }} />
                                                )}
                                            </div>
                                        </div>
                                        <div className='field'>
                                            <p className='label'>팀</p>
                                            <div className='data'>
                                                {teamMap[player.team_id] ? (
                                                    <>
                                                    <img src={`${SERVER_URL}${teamMap[player.team_id].icon}`} alt='team icon' style={{ width: 24, height: 24, marginRight: 4 }} />
                                                    {teamMap[player.team_id].name}
                                                    </>
                                                ) : 'Unknown'}
                                            </div>
                                        </div>

                                        <div className='field column'>
                                            <p className='label'>팀원</p>
                                            <div className='data'>
                                                {playerMap[player.team_id] ? (
                                                    Array.isArray(playerMap[player.team_id]) ? (
                                                        playerMap[player.team_id]
                                                            .filter(teamPlayer => {
                                                                return (
                                                                    teamPlayer.id !== parseInt(id, 10) && // 현재 페이지 선수를 제외
                                                                    player.game === teamPlayer.game // 같은 게임의 팀원만 필터링
                                                                );
                                                            })
                                                            .map((teamPlayer, index) => (
                                                                <Link to={`/player/detail/${teamPlayer.id}`} key={index}>
                                                                    <div className='player-box'>
                                                                        <img src={`${SERVER_URL}${teamPlayer.thumbnail}`} alt='player icon' />
                                                                        {teamPlayer.playId}
                                                                    </div>
                                                                </Link>
                                                            ))
                                                    ) : 'Not an array'
                                                ) : 'Unknown'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {Object.keys(groupedTools).length > 0 && (
                                    <div className='section-wrap'>
                                        <div className='section-title'><img src={`${process.env.PUBLIC_URL}/img/icon-cube.svg`} />사용 장비</div>
                                        <div className='section-inner'>
                                            {Object.entries(groupedTools).map(([categoryName, tools]) => (
                                                <div key={categoryName} className='category-group'>
                                                    <h3>{categoryName}</h3>
                                                    <div className='tools-list'>
                                                        {tools.map((tool, index) => {
                                                            const category2Name = toolCategories[tool[0].cate2]?.name || '기타'; // cate2의 name 가져오기
                                                            return(
                                                                tool[0].link.length < 9 ? (
                                                                    <a key={index} className='tool-item'>
                                                                        <img src={tool[0].thumbnail && tool[0].thumbnail != 'null' ? (
                                                                            `${SERVER_URL}${tool[0].thumbnail}`
                                                                        ) : (
                                                                            defaultImg
                                                                        )}
                                                                        alt={tool[0].name} />
                                                                        <p className='category'>{category2Name}</p>
                                                                        <p className='name'>{tool[0].name}</p>
                                                                    </a>
                                                                ) : (
                                                                    <a href={tool[0].link} target='_blank' key={index} className='tool-item'>
                                                                        <img src={`${SERVER_URL}${tool[0].thumbnail}`} alt={tool[0].name} />
                                                                        <p className='category'>{category2Name}</p>
                                                                        <p className='name'>{tool[0].name}</p>
                                                                    </a>
                                                                )
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {player.settings && (
                                    <div className='section-wrap'>
                                        <div className='section-title'>
                                        {gameMap[player.game] && (
                                            <img src={`${SERVER_URL}${gameMap[player.game].icon}`} className='game-icon'/>
                                            )}    
                                            인게임 세팅
                                        </div>
                                        
                                        <div className='section-inner'>
                                            {renderIngameSettings()}
                                        </div>
                                    </div>
                                )}
                                {player.toolsettings && player.toolsettings !== 'undefined' && JSON.parse(player.toolsettings).length > 0 && (
                                    <div className='section-wrap'>
                                        <div className='section-title'><img src={`${process.env.PUBLIC_URL}/img/icon-settings.svg`} />장비 세팅</div>
                                        <div className='section-inner'>
                                            {renderToolSettings()}
                                        </div>
                                    </div>
                                )}

                                <div className='comment-wrap'>
                                    <p className='comment-sum'><img src={`${process.env.PUBLIC_URL}/img/icon-pencil.svg`} />댓글 {'('}{totalComments}{')'}</p>
                                    <CommentForm playerId={id} onSubmit={handleAddComment} />
                                    <CommentList comments={comments} onAddReply={handleAddComment} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        <LayoutFooter />
        </>
    );
};

export default PlayerDetail;