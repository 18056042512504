import React, { useState, useEffect, useRef } from 'react';
import { createPlayer, updatePlayer, fetchPlayerById, fetchTeams, fetchGames, fetchTools, fetchToolCates, fetchSettingsByGameId, fetchToolSettings, fetchToolSettingById } from '../../api';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Flags from 'react-world-flags';
import countryList from 'react-select-country-list';
import { Modal, Button, Form } from 'react-bootstrap';
import ToolModal from './ToolModal';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const defaultImage = `${SERVER_URL}/uploads/no-image.svg`;
const options = countryList().getData();

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
};

const PlayerForm = ({ playerId, player, onSave, onDelete }) => {
  const handleImageError = (e) => {
    e.target.src = defaultImage;
  }

  const navigate = useNavigate();
  const toolModalRef = useRef();

  const [toolCatesMap, setToolCatesMap] = useState({});
  const [previewThumbnail, setPreviewThumbnail] = useState(null);
  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [allTools, setAllTools] = useState([]);
  const [formData, setFormData] = useState({
    exposure: '노출함',
    game: '',
    team_id: '',
    playId: '',
    name: '',
    nationality: '',
    thumbnail: null,
    snsYoutube: '',
    snsInstagram: '',
    snsFacebook: '',
    snsAfreeca: '',
    snsChzzk: '',
    snsX: '',
    snsTictok: '',
    rel_products: [],
    settings: [],
    toolsettings: [] // 장비 세팅 정보
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showNewFieldModal, setShowNewFieldModal] = useState(false);
  const [toolSettingsOptions, setToolSettingsOptions] = useState([]);
  const [selectedToolSettingId, setSelectedToolSettingId] = useState('');
  const [selectedToolSettings, setSelectedToolSettings] = useState([]);

  const [newField, setNewField] = useState({ name: '', type: 'text', value: '' });
  const [editingCategory, setEditingCategory] = useState('');

  useEffect(() => {
    fetchToolCates().then(response => {
      const toolCatesMap = response.data.reduce((acc, toolCate) => {
        acc[toolCate.id] = toolCate.name;
        return acc;
      }, {});
      setToolCatesMap(toolCatesMap);
    }).catch(error => {
      console.error('There was an error fetching the tool categories!', error);
    });
  }, []);

  useEffect(() => {
    fetchToolSettings().then(response => {
      setToolSettingsOptions(response.data);
    }).catch(error => {
      console.error('Error fetching tool settings:', error);
    });
  }, []);

  useEffect(() => {

    if (playerId) {
      playerId = parseInt(playerId);
      fetchPlayerById(playerId).then((response) => {
        const data = response.data;
        
        if (data && typeof data === 'object' && data.id === playerId) {
          // data가 단일 객체일 때
          const player = data;
          
          setFormData({
            exposure: player.exposure || '노출함',
            game: player.game || '',
            team_id: player.team_id || '',
            name: player.name || '',
            playId: player.playId || '',
            nationality: player.nationality || '',
            thumbnail: player.thumbnail || null,
            snsYoutube: player.snsYoutube || '',
            snsInstagram: player.snsInstagram || '',
            snsFacebook: player.snsFacebook || '',
            snsAfreeca: player.snsAfreeca || '',
            snsChzzk: player.snsChzzk || '',
            snsX: player.snsX || '',
            snsTictok: player.snsTictok || '',
            rel_products: player.rel_products ? JSON.parse(player.rel_products) : [],
            settings: player.settings ? JSON.parse(player.settings) : [],
            toolsettings: player.toolsettings ? JSON.parse(player.toolsettings) : [] // 기존 장비 세팅 정보
          });
          if (player.thumbnail) {
            setPreviewThumbnail(`${SERVER_URL}${player.thumbnail}`);
          } else {
            setPreviewThumbnail(`${SERVER_URL}/uploads/no-image.svg`);
          }
        } else {
          console.error('Unexpected data format:', data);
        }
      }).catch(error => {
        console.error('Error fetching player by ID:', error);
      });
    }
  }, [playerId]);

  useEffect(() => {
    if (formData.game && formData.settings.length === 0) {
      fetchSettingsByGameId(formData.game).then((response) => {
        setFormData((prevData) => ({
          ...prevData,
          settings: response.data.map((setting) => ({
            category: setting.category,
            icon: setting.icon,
            dynamic_fields: JSON.parse(setting.dynamic_fields)
          }))
        }));
      }).catch(error => {
        console.error('Error fetching settings by game ID:', error);
      });
    }
  }, [formData.game, formData.settings.length]);

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, thumbnail: file });
        setPreviewThumbnail(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    fetchTeams().then(response => {
      setTeams(response.data);
    }).catch(error => {
      console.error('There was an error fetching the teams!', error);
    });
  }, []);

  useEffect(() => {
    fetchGames().then(response => {
      setGames(response.data);
    }).catch(error => {
      console.error('There was an error fetching the Games!', error);
    });
  }, []);

  useEffect(() => {
    fetchTools().then(response => {
      setAllTools(response.data);
    }).catch(error => {
      console.error('There was an error fetching the tools!', error);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, nationality: selectedOption.value });
  };

  const handleAddTool = (toolList) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      rel_products: toolList.map(tool => tool.id)
    }));
  };

  const handleModalSave = () => {
    fetchTools().then(response => {
      setAllTools(response.data);
    }).catch(error => {
      console.error('There was an error fetching the tools!', error);
    });
  };

  //장비세팅관련 변수

  const handleAddToolSettings = () => {
    const selectedToolSetting = toolSettingsOptions.find(tool => tool.id === parseInt(selectedToolSettingId));
  
    if (!selectedToolSetting) {
      console.error('No tool settings selected or tool setting not found');
      return;
    }
  
    // 이미 추가된 toolSetting이 있는지 확인
    const isDuplicate = formData.toolsettings.some(setting => setting.toolSettingId === selectedToolSettingId);
  
    if (isDuplicate) {
      console.warn('Tool setting already added');
      return;
    }
  
    const toolSettingData = JSON.parse(selectedToolSetting.settings);
  
    setFormData(prevFormData => ({
      ...prevFormData,
      toolsettings: [
        ...prevFormData.toolsettings,
        {
          toolSettingId: selectedToolSettingId,
          toolSettingName: selectedToolSetting.name,
          settings: toolSettingData
        }
      ]
    }));
  };


  const handleSelectChange = (e) => {
    const selectedSettingId = e.target.value;
    setSelectedToolSettingId(selectedSettingId);

    const selectedSetting = toolSettingsOptions.find(tool => tool.id === parseInt(selectedSettingId));
    if (selectedSetting) {
      setSelectedToolSettings(JSON.parse(selectedSetting.settings));
    } else {
      console.error('Selected setting not found');
    }
  };

  const handleRemoveToolSetting = (index) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      toolsettings: prevFormData.toolsettings.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveIndividualSetting = (toolIndex, settingIndex) => {
    setFormData(prevFormData => {
      const newToolSettings = [...prevFormData.toolsettings];
      newToolSettings[toolIndex] = {
        ...newToolSettings[toolIndex],
        settings: newToolSettings[toolIndex].settings.filter((_, i) => i !== settingIndex)
      };
      return {
        ...prevFormData,
        toolsettings: newToolSettings
      };
    });
  };

  const handleToolSettingChange = (toolIndex, settingIndex, value) => {
    setFormData(prevFormData => {
      const newToolSettings = [...prevFormData.toolsettings];
      newToolSettings[toolIndex] = {
        ...newToolSettings[toolIndex],
        settings: newToolSettings[toolIndex].settings.map((setting, index) =>
          index === settingIndex ? { ...setting, value } : setting
        )
      };
      return {
        ...prevFormData,
        toolsettings: newToolSettings
      };
    });
  };

  //변수 end


  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleModalSave();

    const finalFormData = new FormData();
    finalFormData.append('exposure', formData.exposure);
    finalFormData.append('game', formData.game);
    finalFormData.append('team_id', formData.team_id);
    finalFormData.append('thumbnail', formData.thumbnail);
    finalFormData.append('name', formData.name);
    finalFormData.append('playId', formData.playId);
    finalFormData.append('nationality', formData.nationality);
    finalFormData.append('snsYoutube', formData.snsYoutube.startsWith('https://') ? formData.snsYoutube : `https://${formData.snsYoutube}`);
    finalFormData.append('snsInstagram', formData.snsInstagram.startsWith('https://') ? formData.snsInstagram : `https://${formData.snsInstagram}`);
    finalFormData.append('snsFacebook', formData.snsFacebook.startsWith('https://') ? formData.snsFacebook : `https://${formData.snsFacebook}`);
    finalFormData.append('snsAfreeca', formData.snsAfreeca.startsWith('https://') ? formData.snsAfreeca : `https://${formData.snsAfreeca}`);
    finalFormData.append('snsChzzk', formData.snsChzzk.startsWith('https://') ? formData.snsChzzk : `https://${formData.snsChzzk}`);
    finalFormData.append('snsX', formData.snsX.startsWith('https://') ? formData.snsX : `https://${formData.snsX}`);
    finalFormData.append('snsTictok', formData.snsTictok.startsWith('https://') ? formData.snsTictok : `https://${formData.snsTictok}`);
    finalFormData.append('rel_products', JSON.stringify(formData.rel_products));
    const settingsString = typeof formData.settings === 'string' ? formData.settings : JSON.stringify(formData.settings);
    finalFormData.append('settings', settingsString);

    const toolSettingsString = JSON.stringify(formData.toolsettings);
    finalFormData.append('toolsettings', toolSettingsString);

    if (playerId) {
      updatePlayer(playerId, finalFormData).then(() => {
        onSave();
        navigate('/dashboard/player/list');
      });
    } else {
      createPlayer(finalFormData).then(() => {
        onSave();
        navigate('/dashboard/player/list');
      });
    }
  };

  const handleRemoveTool = (toolId) => {
    const updatedProducts = formData.rel_products.filter((id) => id !== toolId);
    setFormData({ ...formData, rel_products: updatedProducts });
  };

  const handleSettingsChange = (category, fieldIndex, value) => {
    setFormData((prevData) => {
      const settings = typeof prevData.settings === 'string' ? JSON.parse(prevData.settings) : prevData.settings;

      const updatedSettings = settings.map(setting => {
        if (setting.category === category) {
          const updatedFields = setting.dynamic_fields.map((field, index) => {
            if (index === fieldIndex) {
              return { ...field, value: value };
            }
            return field;
          });
          return { ...setting, dynamic_fields: updatedFields };
        }
        return setting;
      });

      return { ...prevData, settings: updatedSettings };
    });
  };

  const parseSettings = (settings) => {
    try {
      return JSON.parse(settings);
    } catch (e) {
      console.error('Error parsing settings:', e);
      return [];
    }
  };

  const parseToolSettings = (settings) => {
    try {
      return JSON.parse(settings);
    } catch (e) {
      console.error('Error parsing settings:', e);
      return [];
    }
  };

  const parsedSettings = Array.isArray(formData.settings) ? formData.settings : parseSettings(formData.settings);
  const parsedToolSettings = Array.isArray(formData.toolsettings) ? formData.toolsettings : parseToolSettings(formData.toolsettings);

  const filteredRelProducts = allTools.filter(tool => 
    formData.rel_products.includes(tool.id)
  );

  const handleAddDynamicField = () => {
    setFormData(prevData => {
      const settings = typeof prevData.settings === 'string' ? JSON.parse(prevData.settings) : prevData.settings;
      
      const updatedSettings = settings.map(setting => {
        if (setting.category === editingCategory) {
          const fieldToAdd = { ...newField };
          return { ...setting, dynamic_fields: [...setting.dynamic_fields, fieldToAdd] };
        }
        return setting;
      });

      return { ...prevData, settings: updatedSettings };
    });

    setNewField({ name: '', type: 'text', value: '' });
    setShowNewFieldModal(false);
  };


  const handleRemoveField = (category, fieldIndex) => {
    setFormData(prevData => {
      const settings = Array.isArray(prevData.settings) ? prevData.settings : parseSettings(prevData.settings);
  
      const updatedSettings = settings.map(setting => {
        if (setting.category === category) {
          const updatedFields = setting.dynamic_fields.filter((_, index) => index !== fieldIndex);
          return { ...setting, dynamic_fields: updatedFields };
        }
        return setting;
      }).filter(setting => setting.dynamic_fields.length > 0);
  
      return { ...prevData, settings: JSON.stringify(updatedSettings) };
    });
  };

  const handleRemoveCategory = (category) => {
    setFormData(prevData => {
      const settings = Array.isArray(prevData.settings) ? prevData.settings : parseSettings(prevData.settings);
  
      const updatedSettings = settings.filter(setting => setting.category !== category);
  
      return { ...prevData, settings: JSON.stringify(updatedSettings) };
    });
  };


  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState({
    category: '',
    icon: '',
    dynamic_fields: []
  });

  const handleAddCategory = () => {
    setFormData(prevData => {
      const settings = typeof prevData.settings === 'string' ? JSON.parse(prevData.settings) : prevData.settings;
      
      const updatedSettings = [...settings, newCategory];
      return { ...prevData, settings: JSON.stringify(updatedSettings) };
    });
    
    setNewCategory({ category: '', icon: '', dynamic_fields: [] });
    setShowNewCategoryModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='title-wrap'>
          {playerId ? (
            <><p className="title">{formData.playId}</p><div className='button-wrap'><button type="submit">수정 완료</button></div></>
          ) : (
            <><p className="title">새 선수</p><div className='button-wrap'><button type="submit">저장</button></div></>
          )}
        </div>
        <div className="sub-title">기본 설정</div>
        
        <div className="input-wrap">
          <p className="label">노출여부</p>
          <label>
            <input type="radio" name="exposure" value="노출함" checked={formData.exposure === '노출함'} onChange={handleChange} />
            노출함
          </label>
          <label>
            <input type="radio" name="exposure" value="노출안함" checked={formData.exposure === '노출안함'} onChange={handleChange} />
            노출안함
          </label>
        </div>

        <div className="input-wrap">
            <p className="label">게임</p>
            <select name="game" value={formData.game} onChange={handleChange}>
                <option value="">게임을 선택해주세요</option>
                {games.map((game) => (
                    <option key={game.id} value={game.id}>
                    {game.name}
                    </option>
                ))}
            </select>
        </div>
        
        <div className="input-wrap">
            <p className="label">팀</p>
            <select name="team_id" value={formData.team_id} onChange={handleChange}>
                <option value="">팀을 선택해주세요</option>
                {teams.map((team) => (
                <option key={team.id} value={team.id}>
                    {team.name}
                </option>
                ))}
            </select>
        </div>

        <div className="input-wrap">
          <p className="label">활동명</p>
          <input type="text" name="playId" value={formData.playId} onChange={handleChange} placeholder="활동명" />
        </div>

        <div className="input-wrap">
          <p className="label">이름</p>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="이름" />
        </div>

        <div className="input-wrap">
            <p className="label">국가</p>
            <Select
                name="nationality"
                className="country-select"
                options={options}
                value={options.find(option => option.value === formData.nationality)}
                onChange={handleCountryChange}
                formatOptionLabel={option => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Flags code={option.value} style={{ width: 20, height: 15, marginRight: 10 }} />
                    {option.label}
                </div>
                )}
            />
        </div>
        
        <div className="input-wrap">
          <p className="label">썸네일</p>
          {previewThumbnail && (<img src={previewThumbnail} className="iconPreview" alt="icon preview" onError={handleImageError} />)}
          <label className="file-button">
            <input type="file" name="thumbnail" onChange={handleThumbnailChange} className='displaynone' accept="image/*" />
            {previewThumbnail ? '변경' : '추가'}
          </label>
        </div>
        <div className="input-wrap">
          <p className="label">SNS 링크</p>
          <div className='sns-wrap'>
            <div className='sns'>
              <img alt="" src={`${SERVER_URL}/uploads/i-youtube.svg`} />
              <div className='sns-input'>
                <span className="fixed-text">https://</span>
                <input type="text" name="snsYoutube" value={formData.snsYoutube.replace(/^https:\/\//, '')} onChange={handleChange} placeholder="유튜브 링크" className="sns-input" />
              </div>
            </div>
            <div className='sns'>
              <img alt="" src={`${SERVER_URL}/uploads/i-instagram.svg`} />
              <div className='sns-input'>
                <span className="fixed-text">https://</span>
                <input type="text" name="snsInstagram" value={formData.snsInstagram.replace(/^https:\/\//, '')} onChange={handleChange} placeholder="인스타그램 링크" className="sns-input" />
              </div>
            </div>
            <div className='sns'>
              <img alt="" src={`${SERVER_URL}/uploads/i-facebook.svg`} />
              <div className='sns-input'>
                <span className="fixed-text">https://</span>
                <input type="text" name="snsFacebook" value={formData.snsFacebook.replace(/^https:\/\//, '')} onChange={handleChange} placeholder="페이스북 링크" className="sns-input" />
              </div>
            </div>
            <div className='sns'>
              <img alt="" src={`${SERVER_URL}/uploads/i-afreeca.svg`} />
              <div className='sns-input'>
                <span className="fixed-text">https://</span>
                <input type="text" name="snsAfreeca" value={formData.snsAfreeca.replace(/^https:\/\//, '')} onChange={handleChange} placeholder="아프리카 링크" className="sns-input" />
              </div>
            </div>
            <div className='sns'>
              <img alt="" src={`${SERVER_URL}/uploads/i-chzzk.svg`} />
              <div className='sns-input'>
                <span className="fixed-text">https://</span>
                <input type="text" name="snsChzzk" value={formData.snsChzzk.replace(/^https:\/\//, '')} onChange={handleChange} placeholder="치지직 링크" className="sns-input" />
              </div>
            </div>
            <div className='sns'>
              <img alt="" src={`${SERVER_URL}/uploads/i-x.svg`} />
              <div className='sns-input'>
                <span className="fixed-text">https://</span>
                <input type="text" name="snsX" value={formData.snsX.replace(/^https:\/\//, '')} onChange={handleChange} placeholder="X 링크" className="sns-input" />
              </div>
            </div>
            <div className='sns'>
              <img alt="" src={`${SERVER_URL}/uploads/i-tiktok.svg`} />
              <div className='sns-input'>
                <span className="fixed-text">https://</span>
                <input type="text" name="snsTictok" value={formData.snsTictok.replace(/^https:\/\//, '')} onChange={handleChange} placeholder="틱톡 링크" className="sns-input" />
              </div>
            </div>
          </div>
        </div>

        <div className="sub-title">사용 장비 설정</div>

        <div className="input-wrap">
          <p className="label">사용 장비</p>
          <div className="input-table-wrap">
            <button type="button" onClick={() => setShowModal(true)}>장비 추가</button>
            <div className="table-head">
              <div className="idx">No</div>
              <div className="thumbnail">썸네일</div>
              <div className="cate">카테고리</div>
              <div className="name">제품명</div>
              <div className="link">링크</div>
              <div className="controll">관리</div>
            </div>
            <div className='table-body'>
              {filteredRelProducts.map((tool, index) => (
                <li key={tool.id}>
                  <div className="idx">{index + 1}</div>
                  <div className="thumbnail">
                    {tool.thumbnail ? (
                      <img src={`${SERVER_URL}${tool.thumbnail}`} />
                    ) : (
                      <img src={`${SERVER_URL}/uploads/no-image.svg`} />
                    )}
                  </div>
                  <div className="cate">{toolCatesMap[tool.cate2]}</div>
                  <div className="name">{tool.name}</div>
                  <div className="name">{tool.link}</div>
                  <div className="controll">
                    <Button onClick={() => handleRemoveTool(tool.id)} className='delete'>삭제</Button>
                  </div>
                </li>
              ))}
            </div>
          </div>
        </div>

        <div className="sub-title">장비 세팅 설정</div>

        <div className="input-wrap selection">
          <p className="label">저장된 세팅 추가</p>
          <select onChange={handleSelectChange} value={selectedToolSettingId} className='addSetting'>
            <option value="">장비 세팅을 선택하세요</option>
            {toolSettingsOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </select>
          <button type="button" onClick={handleAddToolSettings}>추가</button>
        </div>

        {formData.toolsettings.map((toolSetting, toolIndex) => (
          <div className='wrap'>
            <div className='input-wrapper'>
              <div className='idx'>
                {toolIndex + 1}
                <button type="button" onClick={() => handleRemoveToolSetting(toolIndex)} className='delete'>삭제</button>
              </div>
              <div className='settings-wrap' key={toolIndex}>
                <div className='input-wrap'>
                  <p className='label'>분류명</p>
                  <input type='text' value={toolSetting.toolSettingName || 'Unknown'}  className='ptNone'/>
                </div>
                <div className='input-wrap'>
                  <p className='label'>항목</p>
                  <div className='field-wrap'>
                    {toolSetting.settings.map((setting, settingIndex) => (
                      <div key={settingIndex} className='field ingame'>
                        <input type='text' value={setting.name}  className='ptNone'/>
                        <input
                          type={setting.type}
                          value={setting.value}
                          onChange={(e) => handleToolSettingChange(toolIndex, settingIndex, e.target.value)}
                        />
                        <button type="button" onClick={() => handleRemoveIndividualSetting(toolIndex, settingIndex)} className='delete'>삭제</button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="sub-title">인게임 설정</div>

        {parsedSettings.map((setting, index) => (
          <div className='wrap'>
            <div className='input-wrapper'>
              <div className='idx'>
                {index + 1}
                <Button onClick={() => handleRemoveCategory(setting.category)} className='delete'>삭제</Button>
              </div>
              <div className='settings-wrap' key={setting.category}>
                <div className='input-wrap'>
                  <p className='label'>분류명</p>
                  <input type='text' value={setting.category}  className='ptNone'/>
                </div>
                <div className='input-wrap'>
                  <p className='label'>항목</p>
                  <div className='field-wrap'>
                  {setting.dynamic_fields.map((field, index) => (
                    <div key={index} className='field ingame'>
                      <input type='text' value={field.name} className='ptNone'/>
                      {field.type === 'radio' ? (
                        <div className='radio-wrap'>
                          {field.options.map((option, optionIndex) => (
                            <label key={optionIndex}>
                              <input
                                type="radio"
                                name={field.name}
                                value={option}
                                checked={field.value === option}
                                onChange={(e) => handleSettingsChange(setting.category, index, e.target.value)}
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      ) : (
                        <input
                          type={field.type}
                          name={field.name}
                          value={field.value}
                          onChange={(e) => handleSettingsChange(setting.category, index, e.target.value)}
                        />
                      )}
                      <Button onClick={() => handleRemoveField(setting.category, index)} className='delete'>삭제</Button>
                    </div>
                  ))}
                  </div>
                </div>
                <div className='input-wrap'>
                  <p className='label'></p>
                  <button 
                    type="button" 
                    onClick={() => {
                      setEditingCategory(setting.category);
                      setNewField({ ...newField, category: setting.category });
                      setShowNewFieldModal(true);
                    }}
                  >
                    항목 추가
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <button type="button" onClick={() => setShowNewCategoryModal(true)} className='bottom-button-wrap'>분류 추가</button>

        {/* 새 필드 추가 양식 */}
        {/* 모달창 */}
          <Modal show={showNewFieldModal} onHide={() => setShowNewFieldModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>새 필드 추가</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>필드 이름</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="필드 이름"
                    value={newField.name}
                    onChange={(e) => setNewField({ ...newField, name: e.target.value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>필드 값</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="필드 값"
                    value={newField.value}
                    onChange={(e) => setNewField({ ...newField, value: e.target.value })}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button 
                variant="primary" 
                onClick={() => {
                  handleAddDynamicField(); // 필드 추가
                  setShowNewFieldModal(false); // 모달 닫기
                }}
                className='modalSubmit'
                >
                저장
              </Button>
            </Modal.Footer>
          </Modal>

        <Modal show={showNewCategoryModal} onHide={() => setShowNewCategoryModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>새 분류 추가</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>분류 이름</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="분류 이름"
                  value={newCategory.category}
                  onChange={(e) => setNewCategory({ ...newCategory, category: e.target.value })}
                />
              </Form.Group>
              <Form.Group className='displaynone'>
                <Form.Label>아이콘 URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="아이콘 URL"
                  value={newCategory.icon}
                  onChange={(e) => setNewCategory({ ...newCategory, icon: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <div className='label-wrap'>
                  <Form.Label>항목</Form.Label>
                  {/* 동적 필드를 추가하는 UI */}
                  <Button
                    onClick={() => {
                      setNewCategory(prev => ({
                        ...prev,
                        dynamic_fields: [...prev.dynamic_fields, { name: '', type: 'text', value: '' }]
                      }));
                    }}
                    className='btnSubmit'
                  >
                    추가
                  </Button>
                </div>
                {newCategory.dynamic_fields.map((field, index) => (
                  <div key={index} className='input-wrap'>
                    <Form.Control
                      type="text"
                      placeholder="항목명"
                      value={field.name}
                      onChange={(e) => {
                        const updatedFields = [...newCategory.dynamic_fields];
                        updatedFields[index].name = e.target.value;
                        setNewCategory({ ...newCategory, dynamic_fields: updatedFields });
                      }}
                    />
                    <Form.Control
                      type="text"
                      placeholder="항목값"
                      value={field.value}
                      onChange={(e) => {
                        const updatedFields = [...newCategory.dynamic_fields];
                        updatedFields[index].value = e.target.value;
                        setNewCategory({ ...newCategory, dynamic_fields: updatedFields });
                      }}
                    />
                  </div>
                ))}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleAddCategory} className='modalSubmit'>
              저장
            </Button>
          </Modal.Footer>
        </Modal>
        <div className='bottom-button-wrap'>
          <button type="submit">저장</button>
        </div>
      </form>

    <ToolModal
        ref={toolModalRef}
        show={showModal}
        onClose={() => setShowModal(false)}
        onAddTool={handleAddTool}
        selectedTools={formData.rel_products}
        onSave={handleModalSave}
      />
    </>
  );
};

export default PlayerForm;
