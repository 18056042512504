import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

// MediaQueryContext 생성
const MediaQueryContext = createContext();

export const MediaQueryProvider = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1280 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1280 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [device, setDevice] = useState('desktop');

  useEffect(() => {
    if (isMobile) {
      setDevice('mobile');
    } else if (isTablet) {
      setDevice('tablet');
    } else {
      setDevice('desktop');
    }
  }, [isDesktop, isTablet, isMobile]);

  return (
    <MediaQueryContext.Provider value={device}>
      {children}
    </MediaQueryContext.Provider>
  );
};

// Custom Hook: MediaQueryContext 사용
export const useDevice = () => {
  return useContext(MediaQueryContext);
};